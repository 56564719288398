import React from 'react';
import appStore from '../../_assets/images/appStore.png'

function MarketApple() {
  return (
    <a href='https://apps.apple.com/kz/app/%D1%83%D1%87%D0%B5%D1%82-%D0%BF%D0%BE%D1%81%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D0%B9/id6472644316'>
      <img src={appStore} />
    </a>
  );
}

export default MarketApple;
